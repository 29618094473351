import firebase from '@/firebase';

const db = firebase.firestore();

const { TimeStamp, GeoPoint } = firebase.firestore;
export { TimeStamp, GeoPoint };

db.settings({
  timestampsInSnapshots: true,
});

export default db;
