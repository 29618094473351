<template>
  <div id="app">
    <div class="container is-fluid">
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <h1 class="title is-3">Declaratieformulier</h1>
          <h2 class="subtitle">
            {{stepDescription}}
            <b-button
              size="is-small"
              type="is-primary"
              id="btn-back"
              v-if="currentStep > 1"
              @click="$router.go(-1)"
              rounded
            >
              Terug
            </b-button>
            <b-button
              size="is-small"
              type="is-primary"
              id="btn-back"
              v-if="currentStep == 1"
              @click="handleLoginOrLogout"
              rounded
            >
              {{loggedInOrOut}}
            </b-button>
          </h2>
        </div>
      </div>
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'App',
  methods: {
    ...mapActions('auth', ['logout']),
    handleLoginOrLogout() {
      if (this.isLoggedIn) {
        this.logout();
      }

      this.$router.push('/');
    },
  },
  computed: {
    ...mapState('auth', ['user', 'isLoggedIn']),
    currentStep() {
      return this.$route.meta.step;
    },
    stepDescription() {
      return this.$route.meta.stepDescription;
    },
    loggedInOrOut() {
      return (this.isLoggedIn) ? 'Loguit' : 'Login';
    },
  },
};
</script>

<style>
#app {
  margin-top: 1em;
  height: calc(100vh - 1em);
}

#btn-back {
  float: right;
}

.container {
  height: 100%;
}

.title {
  font-weight: 300;
}

.subtitle {
  font-weight: 300;
}

.sticky-button {
  margin-top: 3em;
}

*.is-primary, .switch:hover input[type=checkbox]:checked+.check,
.switch:hover input[type=checkbox]:checked+.check  {
    background-color: #4BBCDA !important;
}

.switch input[type=checkbox]:checked+.check {
  background-color: #4BBCDA !important;
}

.select:not(.is-multiple):not(.is-loading):after {
  border-color: #4BBCDA !important;
}

.input:active, .input:focus, .is-active.input,
.is-active.textarea, .is-focused.input, .is-focused.textarea,
.select select.is-active, .select select.is-focused, .select select:active,
.select select:focus, .taginput .is-active.taginput-container.is-focusable,
.taginput .is-focused.taginput-container.is-focusable,
.taginput .taginput-container.is-focusable:active,
.taginput .taginput-container.is-focusable:focus, .textarea:active,
.textarea:focus {
  border-color: #4BBCDA !important;
  box-shadow: 0 0 0 0.125rem rgb(26 173 207 / 25%) !important;
}

*.switch input[type=checkbox]:active:checked+.check,
.switch input[type=checkbox]:focus:checked+.check {
  background-color: #4BBCDA !important;
  box-shadow: 0 0 0.5em #4BBCDA;
}

*.is-primary:hover, *.is-primary:focus, *.is-primary:active {
    background-color: #1AADCF !important;
}

.upload-draggable.is-primary {
  background-color: transparent !important;
}

@media only screen and (max-width: 768px) {
  .sticky-button {
    position: absolute;
    margin-top: 0;
    width: 83.3333333333% !important;
    bottom: 1em;
  }
}
</style>
