/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
const getDefaultState = () => ({
  name: '',
  phone: '',
  iban: '',
  date_added: new Date().toLocaleDateString(),
  declarations: [],
  attachments: [],
});

const state = getDefaultState();

const mutations = {
  updateName(state, name) {
    state.name = name;
  },
  updatePhone(state, phone) {
    state.phone = phone;
  },
  updateIBAN(state, iban) {
    state.iban = iban;
  },
  removeDeclaration(state, index) {
    state.declarations.splice(index, 1);

    for (let i = 0; i < state.declarations.length; i++) {
      state.declarations[i].index = i;
    }
  },
  addDeclaration(state, declaration) {
    declaration.index = state.declarations.length;

    state.declarations.push(declaration);
  },
  addAttachment(state, attachments) {
    state.attachments.push(attachments);
  },
  removeAttachment(state, index) {
    state.attachments.splice(index, 1);
    for (let i = 0; i < state.attachments.length; i++) {
      state.attachments[i].index = i;
    }
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  resetState({ commit }) {
    commit('resetState');
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
