<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="Step2">
    <div class="columns is-desktop">
      <div class="columns">
        <div class="column">
          <b-field
            label="Omschrijving"
            custom-class="is-medium"
            label-position="on-border"
          >
            <b-input size="is-medium" v-model="description"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-5">
          <b-field
            label="Werkgroep"
            custom-class="is-medium"
            label-position="on-border"
          >
            <b-select
              placeholder="Select a name"
              size="is-medium"
              v-model="workgroup"
            >
              <option v-for="workgroup in getWorkgroups()" :key="workgroup">
                {{ workgroup }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field
            label="Prijs"
            custom-class="is-medium"
            label-position="on-border"
          >
            <b-input
              type="number"
              size="is-medium"
              v-model.number="price"
              min="0"
              step="any"
              trim
            ></b-input>
          </b-field>
        </div>
        <div class="column is-3">
          <b-button
            type="is-primary"
            size="is-large"
            expanded
            rounded
            :disabled="!canAdd"
            @click="add()"
          >
            <b-icon icon="plus-circle-outline"> </b-icon>
          </b-button>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column" id="table">
        <b-table
          :data="items"
          :mobile-cards="false"
        >
          <b-table-column field="description" label="Omschr." v-slot="props">
                {{ props.row.description }}
          </b-table-column>
          <b-table-column field="workgroup" label="Werkgr." v-slot="props">
                {{ props.row.workgroup }}
          </b-table-column>
          <b-table-column field="price" label="Prijs"  width="90" v-slot="props">
                &euro; {{ props.row.price }}
          </b-table-column>
          <b-table-column field="index" label="Actie" v-slot="props">
                <b-button
                  size="is-small"
                  type="is-danger"
                  rounded
                  @click="remove(props.row.index)"
                >
                  <b-icon
                    size="is-small"
                    icon="delete"
                  ></b-icon>
                </b-button>
          </b-table-column>
          <template #footer>
            <div class="has-text-right">Totaal: &euro;{{ totalPrice }}</div>
          </template>
        </b-table>
      </div>
    </div>
    <b-button
      class="sticky-button"
      type="is-primary"
      size="is-medium"
      :disabled="!canContinue"
      @click="save()"
      expanded
      rounded
    >
      Volgende
    </b-button>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Step2',
  data() {
    return {
      description: '',
      workgroup: null,
      price: null,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState('workgroups', ['workgroups']),
    items: {
      get() {
        return this.$store.state.declaration.declarations;
      },
    },
    descriptionState() {
      return this.description.length >= 1;
    },
    workgroupState() {
      return this.workgroup !== null;
    },
    priceState() {
      return this.price !== null && this.price !== '' && this.price >= 0;
    },
    canAdd() {
      return this.descriptionState && this.workgroupState && this.priceState;
    },
    canContinue() {
      return this.items.length > 0;
    },
    totalPrice() {
      let total = 0;

      for (let i = 0; i < this.items.length; i += 1) {
        total += this.items[i].price;
      }

      return total.toFixed(2);
    },
  },
  methods: {
    ...mapActions('workgroups', ['init']),
    getWorkgroups() {
      let res = [];
      for (let i = 0; i < this.workgroups.length; i += 1) {
        res = this.workgroups[i].workgroups;
      }

      return res;
    },
    add() {
      this.$store.commit('declaration/addDeclaration', {
        description: this.description,
        workgroup: this.workgroup,
        price: this.price,
      });

      this.description = '';
      this.workgroup = null;
      this.price = null;
    },
    remove(i) {
      this.$store.commit('declaration/removeDeclaration', i);
    },
    save() {
      if (!this.canContinue) return;

      this.$router.push({
        name: 'Step3',
      });
    },
  },
};
</script>
<style>
#table {
  padding: 0;
}
#table .b-table .table-wrapper {
  overflow: hidden;
}
</style>
