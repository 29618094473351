import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue';
import Step1 from '../views/Step1.vue';
import Step2 from '../views/Step2.vue';
import Step3 from '../views/Step3.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      step: 0,
      stepDescription: 'Login',
    },
  },
  {
    path: '/step1',
    name: 'Step1',
    component: Step1,
    meta: {
      step: 1,
      stepDescription: 'Stap 1 - Persoonlijke gegevens',
    },
  },
  {
    path: '/step2',
    name: 'Step2',
    component: Step2,
    meta: {
      step: 2,
      stepDescription: 'Stap 2 - Declaratie(s)',
    },
  },
  {
    path: '/step3',
    name: 'Step3',
    component: Step3,
    meta: {
      step: 3,
      stepDescription: 'Stap 3 - Bonnen/Facturen',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if ((to.name !== 'Home') && !store.state.auth.isLoggedIn) {
    next('/');
  } else {
    next();
  }
});

export default router;
