import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyCVWd00OQynQSEgKOJ_u-wIwtXS2tWn698',
  authDomain: 'cultus-declarations.firebaseapp.com',
  projectId: 'cultus-declarations',
  storageBucket: 'cultus-declarations.appspot.com',
  messagingSenderId: '465694066119',
  appId: '1:465694066119:web:a1c95f1f77ad2e113745e2',
  measurementId: 'G-DTJ9ESFHS2',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
