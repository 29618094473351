/* eslint-disable import/prefer-default-export */
export class LocalStorageHelper {
  constructor() {
    this.objects = {};
  }

  add(key, value) {
    if (this.keyExist(key)) return false;

    this.objects[key] = value;

    return this;
  }

  get(name) {
    if (!this.hasStorage) return false;

    return JSON.parse(localStorage.getItem(name));
  }

  remove(key) {
    if (!this.keyExist(key)) return false;

    delete this.objects[key];

    return this;
  }

  save(name) {
    if (!this.hasStorage) return false;

    localStorage.setItem(name, JSON.stringify(this.objects));
    this.objects = {};

    return this;
  }

  delete(key) {
    if (!this.hasStorage) return false;

    localStorage.removeItem(key);

    return this;
  }

  keyExist(key) {
    return this.objects[key] !== undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  hasStorage() {
    return typeof Storage !== 'undefined';
  }
}
