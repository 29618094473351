import Vue from 'vue';
import Vuex from 'vuex';

import { vuexfireMutations } from 'vuexfire';

// Import stores below
import workgroups from './workgroups';
import declarations from './declarations';
import declaration from './declaration';
import users from './users';
import auth from './auth';

Vue.use(Vuex);

export default new Vuex.Store({
  mutations: {
    ...vuexfireMutations,
  },
  modules: {
    auth,
    workgroups,
    declarations,
    declaration,
    users,
  },
});
