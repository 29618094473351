<template>
  <div class="home">
    <section>
        <b-field
          label="Naam"
          :type="{ 'is-danger': !nameState }"
          :message="{ 'Vul een naam in': !nameState }"
          custom-class="is-medium"
          label-position="on-border"
        >
          <b-input v-model="name" size="is-medium"></b-input>
        </b-field>
        <b-field
          label="Telefoonnummer"
          :type="{ 'is-danger': !phoneState }"
          :message="{ 'Vul een geldig telefoonnummer in': !phoneState }"
          custom-class="is-medium"
          label-position="on-border"
        >
          <b-input type="tel" v-model="phone" size="is-medium"></b-input>
        </b-field>
        <b-field
          label="IBAN"
          :type="{ 'is-danger': !ibanState }"
          :message="{ 'Vul een geldig IBAN-nummer in': !ibanState }"
          custom-class="is-medium"
          label-position="on-border"
        >
          <b-input v-model="iban" size="is-medium"></b-input>
        </b-field>
        <b-field>
          <b-switch v-model="saveInput" size="is-medium">
            Onthoud mijn gegevens
          </b-switch>
        </b-field>
    </section>
    <b-button
      class="sticky-button"
      type="is-primary"
      size="is-medium"
      :disabled="!canContinue"
      @click="save()"
      expanded
      rounded
    >
      Volgende
    </b-button>
  </div>
</template>

<script>
import * as IBAN from 'iban';
import { LocalStorageHelper } from '@/plugins/LocalStorageHelper';

export default {
  name: 'Step1',
  data() {
    return {
      saveInput: false,
    };
  },
  computed: {
    nameState() {
      return this.name.length >= 1;
    },
    phoneState() {
      const phoneRegex = /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;
      return phoneRegex.test(this.phone);
    },
    ibanState() {
      return IBAN.isValid(this.iban);
    },
    canContinue() {
      return this.nameState && this.phoneState && this.ibanState;
    },
    name: {
      get() {
        return this.$store.state.declaration.name;
      },
      set(value) {
        this.$store.commit('declaration/updateName', value);
      },
    },
    phone: {
      get() {
        return this.$store.state.declaration.phone;
      },
      set(value) {
        this.$store.commit('declaration/updatePhone', value);
      },
    },
    iban: {
      get() {
        return this.$store.state.declaration.iban;
      },
      set(value) {
        this.$store.commit('declaration/updateIBAN', this.ibanFormatter(value));
      },
    },
  },
  methods: {
    ibanFormatter(value) {
      return IBAN.printFormat(value, ' ');
    },
    save() {
      if (!this.canContinue) return;
      const helper = new LocalStorageHelper();

      if (this.saveInput) {
        helper
          .add('name', this.name)
          .add('phone', this.phone)
          .add('iban', this.iban)
          .save('user');
      } else {
        helper.delete('user');
      }

      this.$router.push({
        name: 'Step2',
      });
    },
  },
  mounted() {
    const helper = new LocalStorageHelper();
    const user = helper.get('user');

    if (user === null) return;

    this.name = user.name;
    this.phone = user.phone;
    this.iban = user.iban;
    this.saveInput = true;
  },
};
</script>
<style>
.is-large {
  font-size: 1.2rem !important;
  font-weight: 400;
}
</style>
