<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="home">
    <div class="columns">
      <div class="column">
        <b-button
          @click="login(googleProvider)"
          type="is-primary"
          icon-left="google"
          size="is-large"
          expanded
          rounded
        > &ensp;Login met Google</b-button>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-button
          @click="login(facebookProvider)"
          type="is-primary"
          icon-left="facebook"
          size="is-large"
          expanded
          rounded
        > &ensp;Login met Facebook</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import firebase from '@/firebase';

export default {
  name: 'Home',
  data() {
    return {
      googleProvider: firebase.auth.GoogleAuthProvider,
      facebookProvider: firebase.auth.FacebookAuthProvider,
    };
  },
  computed: mapState('auth', ['user', 'isLoggedIn']),
  methods: mapActions('auth', ['login', 'logout']),
};
</script>
<style>
.upload.control {
  width: 100%;
}

.upload.control .upload-draggable {
  width: 100%;
}
</style>
