import { firestoreAction } from 'vuexfire';
import db from '@/db';

const state = {
  workgroups: [],
};

const actions = {
  init: firestoreAction(({ bindFirestoreRef }) => {
    bindFirestoreRef('workgroups', db.collection('workgroups'));
  }),
};

export default {
  namespaced: true,
  state,
  actions,
};
