<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="Step3">
    <div class="columns">
      <div class="column">
        <section>
          <b-field>
            <b-upload v-model="dropFiles" multiple drag-drop>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"> </b-icon>
                  </p>
                  <p>
                    Sleep afdeelding(en)/PDF bestand(en) hier <br />
                     of klik hier om bestanden te uploaden
                  </p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags">
            <span
              v-for="(file, index) in dropFiles"
              :key="index"
              class="tag is-primary"
            >
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile(index)"
              ></button>
            </span>
          </div>
        </section>
      </div>
      <div class="column">
        <b-button expanded type="is-primary" :disabled="!canSubmit" @click="submit()">
          Inleveren
        </b-button>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import firebase from '@/firebase';
import { mapState, mapActions } from 'vuex';
import axios from 'axios';

const WEBHOOK_ENDPOINT = 'https://hook.integromat.com/ahfc9mhnv264i0lp934qx7r3g126m61w';

export default {
  name: 'Step3',
  mounted() {
    this.init();
  },
  computed: {
    ...mapState('declarations', ['declarations']),
    canSubmit() {
      return this.dropFiles.length > 0;
    },
    name: {
      get() {
        return this.$store.state.declaration.name;
      },
    },
    items: {
      get() {
        return this.$store.state.declaration.declarations;
      },
    },
    phone: {
      get() {
        return this.$store.state.declaration.phone;
      },
    },
    iban: {
      get() {
        return this.$store.state.declaration.iban;
      },
    },
    totalPrice() {
      let total = 0;

      for (let i = 0; i < this.items.length; i += 1) {
        total += this.items[i].price;
      }

      return total.toFixed(2);
    },
  },
  data() {
    return {
      dropFiles: [],
      uploadedFiles: [],
      failedFiles: [],
      isLoading: false,
    };
  },
  methods: {
    ...mapActions('declarations', ['init', 'createDeclaration']),
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    async submit() {
      if (!this.canSubmit) return;

      this.isLoading = true;

      let files = this.dropFiles;

      if (this.failedFiles.length > 0) files = this.failedFiles;

      await Promise.all(files.map((file, index) => this.uploadFile(file, index)));

      if (this.failedFiles.length > 0) {
        this.submit();
        return;
      }

      this.uploadedFiles.map((item) => this.$store.commit('declaration/addAttachment', item));

      const toSave = {
        ...this.$store.state.declaration,
        date_added: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await this.createDeclaration(toSave);
      await axios.post(WEBHOOK_ENDPOINT, {
        declarant: toSave.name,
        itemAmount: this.items.length,
        totalPrice: this.totalPrice,
      });

      this.isLoading = false;
      this.$buefy.dialog.alert({
        message: 'Je declaratie is verstuurd!',
        canCancel: false,
        onConfirm: () => {
          this.$store.commit('declaration/resetState');
          this.$router.push('Step1');
        },
      });
    },
    uploadFile(file, index) {
      const uploadedFile = `${this.name.replace(' ', '_')}_Attachment_${index}_${Date.now()}_${file.name}`;
      return firebase.storage().ref(`declarations/${uploadedFile}`).put(file)
        .then(() => {
          this.uploadedFiles.push(uploadedFile);
        })
        .catch((error) => {
          console.log(error);
          this.failedFiles.push(file);
        });
    },
  },
};
</script>
<style>
.upload.control {
  width: 100%;
}

.upload.control .upload-draggable {
  width: 100%;
}
</style>
