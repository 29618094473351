import { firestoreAction } from 'vuexfire';
import db from '@/db';

const state = {
  declarations: [],
};

const actions = {
  init: firestoreAction(({ bindFirestoreRef }) => {
    bindFirestoreRef('declarations', db.collection('declarations'));
  }),
  // eslint-disable-next-line no-unused-vars
  createDeclaration({ context }, declaration) {
    return db.collection('declarations').add(declaration);
  },
};

export default {
  namespaced: true,
  state,
  actions,
};
