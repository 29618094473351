import firebase from '@/firebase';

const state = {
  user: {},
  isLoggedIn: false,
};

const mutations = {
  // eslint-disable-next-line no-shadow
  setUser(state, user) {
    if (user) {
      state.user = user;
      state.isLoggedIn = true;
    } else {
      state.user = {};
      state.isLoggedIn = false;
    }
  },
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async login({ commit }, ProviderType) {
    const provider = new ProviderType();
    await firebase.auth().signInWithPopup(provider);
  },
  async logout() {
    await firebase.auth().signOut();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
